
import { Component, Vue, Watch } from '@/lib/decorator';

import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import StarIcon from '@/components/icons/Star.vue';

import ShopsModule from '@/store/shops';
import AppTable from '@/components/ui/table/Table.vue';
import { StatusListInterface } from '@/interfaces/ui/table/status.interface';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import StatusActive from '@/components/table-items/StatusActive.vue';
import AppTableColumnContext from '@/components/ui/table/TableColumnContext.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { EventChangeSortHeader, TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import { getShopPhones } from '@/api/shop';
import { makePhoneCall } from '@/lib/util/phone';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import userModule from '@/store/user';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    AppTableColumnContext,
    StatusActive,
    TextPhone,
    TextDatetime,
    AppTable,
    AppHeaderMain,
    StarIcon,
  },
})
export default class ShopsMain extends Vue {
  headers: TableHeaderInterface[] = [];
  hiddenHeaders: TableHeaderInterface[] = [];
  statusList: StatusListInterface = {
    over: '#6E258B',
    notActive: '#FF4B3A',
  };

  getStatus(row: { isActive: boolean; hasOverNeed: boolean }): { color: string; tooltip: string } | undefined {
    if (!row.isActive) {
      return { color: this.statusList.notActive, tooltip: 'Объект неактивен' };
    }

    if (row.hasOverNeed) {
      return { color: this.statusList.over, tooltip: 'В объекте перенайм' };
    }
  }

  get canUserEditShop(): boolean {
    return userModule.userHasPermission('CAN_EDIT_CLIENT_MARKET');
  }

  get isSettingColumn(): boolean {
    return ShopsModule.isSettingColumn;
  }

  get settings(): PageInterface {
    return ShopsModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return ShopsModule.filter.filterSettings;
  }

  get shopDetailLink(): 'shop_shift' | 'shop_statistics' | 'shop_logs' | '' {
    return ShopsModule.shopDetailLink;
  }

  public formatRating(value: number): string {
    return value.toString().substring(0, 3);
  }

  public toAdd(): void {
    this.$router.push({ name: 'shop_add' });
  }

  @Watch('settings.table.hiddenTitles')
  getHiddenTitles(hiddenTitles: TableHeaderInterface[]): void {
    this.hiddenHeaders = [...hiddenTitles];
  }

  @Watch('settings.table.titles')
  getTitles(titles: TableHeaderInterface[]): void {
    this.headers = [...titles];
  }

  async resetFilters(): Promise<void> {
    await ShopsModule.setPageToBegin();
    await ShopsModule.clearShopsSort();
    await ShopsModule.filter.resetFilter();
    await ShopsModule.getList();
  }

  async updateList(): Promise<void> {
    await ShopsModule.setPageToBegin();
    await ShopsModule.filter.updateFilter();
    await ShopsModule.getList();
  }

  toEdit(id: string): void {
    this.$router.push({ name: 'shop_shift', params: { shopId: id } });
  }

  selectAmount(value: string): void {
    ShopsModule.updatePageAmountItems(value);
  }

  toSettings(): void {
    ShopsModule.updateIsSettingsColumn(true);
  }

  closeSettings(): void {
    ShopsModule.updateIsSettingsColumn(false);
  }

  changeVisibleHeaders(e: EventChangeSortHeader): void {
    const tmphiddenTitles = [...this.hiddenHeaders];
    const tmpTitles = e.headers;
    let element;
    switch (e.changed.type) {
      case 'update':
        element = tmpTitles[e.changed.oldIndex];
        tmpTitles.splice(e.changed.oldIndex, 1);
        tmpTitles.splice(e.changed.newIndex, 0, element);
        this.headers = tmpTitles;

        return;
      case 'add':
        element = tmphiddenTitles[e.changed.oldIndex];
        tmphiddenTitles.splice(e.changed.oldIndex, 1);
        tmpTitles.splice(e.changed.newIndex, 0, element as never);
        this.hiddenHeaders = tmphiddenTitles;
        this.headers = tmpTitles;

        return;
      case 'remove':
        element = tmpTitles[e.changed.oldIndex];
        tmpTitles.splice(e.changed.oldIndex, 1);
        tmphiddenTitles.splice(e.changed.newIndex, 0, element as never);
        this.hiddenHeaders = tmphiddenTitles;
        this.headers = tmpTitles;

        return;
    }
  }

  startDrag(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.addEventListener('dragover', this.dragenter, { capture: true });
    element.addEventListener('dragleave', this.dragleave, { capture: false });
  }

  endDrag(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.removeEventListener('dragover', this.dragenter);
    element.removeEventListener('dragleave', this.dragleave);
  }

  dragenter(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.classList.add('target-hover');
  }

  dragleave(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.classList.remove('target-hover');
  }

  saveColumnSettings(): void {
    ShopsModule.saveSettingsToStore({
      hidden: this.hiddenHeaders,
      visible: this.headers,
    });
  }

  cancelColumnSettings(): void {
    this.closeSettings();

    this.hiddenHeaders =
      ShopsModule.hiddenTitles && ShopsModule.hiddenTitles.length
        ? [...ShopsModule.hiddenTitles]
        : 'hiddenTitles' in this.settings.table && Array.isArray(this.settings.table.hiddenTitles)
        ? [...this.settings.table.hiddenTitles]
        : [];

    this.headers =
      ShopsModule.orderTitles && ShopsModule.orderTitles.length
        ? [...ShopsModule.orderTitles]
        : 'titles' in this.settings.table && Array.isArray(this.settings.table.titles)
        ? [...this.settings.table.titles]
        : [];
  }

  sort(header: TableHeaderInterface): void {
    ShopsModule.sort({ field: header.id, sort: header.sort?.value || '' });
  }

  initVacancies(): void {
    const client: { id: number; value: string } = this.filterSettings.filterModel.client.current as {
      id: number;
      value: string;
    };

    if (!Number(client.id)) {
      return;
    }

    ShopsModule.updateClients({
      key: 'client',
      value: client,
      clear: true,
    });
  }

  async makeCall(shopId: string, phoneIndex: number): Promise<void> {
    try {
      const phones = await getShopPhones(shopId);
      makePhoneCall(phones, phoneIndex);
    } catch (error) {
      console.error(error);
    }
  }

  async mounted(): Promise<void> {
    await ShopsModule.init();
    await this.initVacancies();
  }
}
